import React from "react";
import mezcalHomeWebp from "../img/mezcalHome.webp";
import Description_1 from "./Description_1";
import Slider from "./Slider";
import ContactoDefault from "./ContactoDefault";
import Video from "./Video";
export default function Home(props) {
  
    return (
        <div >
            <Video />
                <picture>
                    <img id="mezcalNoHome" src={mezcalHomeWebp} width={1350} alt="mezcalHome" />
                </picture>
            <Description_1 />
            <div className="carrusel">
                <Slider />
                <ContactoDefault/>
            </div>
        </div>
    )
}


