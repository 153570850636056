import React from "react";
import Footer from "./Footer";
import Redes from "./Redes";

export default function Contacto(props) {
    return (
        <div>
            <div className="Redes">
                <Redes />
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
}