import React from "react";
import Productos from "./Productos";
import joven from "../img/joven.jpg";
//import jovenWebp from "../img/joven.webp";
import "../index.css";
const Item = () => {
    return (
        <div className="producto">
            <div className=" pl-2 pr-2">
                <Productos
                    nombre="Mezcal El Gran Remedio"
                    imagen={`${joven}`}
                    frase1="Único con doble destilación "
                    frase2="100% agave cupreata"
                    cuerpo1="Elaborado con mucho amor de manera artesanal en las montañas áridas de Tierra caliente, Guerrero."
                    cuerpo2="Tipo/ categoría: Blanco/joven
                    Agave empleado: cupreata Crecimiento del agave: 7 a 10 años
                    Destilaciones: 2
                    Tipo de horno: de tierra y piedra
                    Tipo de molino: pileta de piedra y mazo de madera
                    Tipo de destilación: olla de madera con fondo de cobre
                    Zona de origen: Región Tierra Caliente, Guerrero
                    Riqueza alcohólica: 45º
                    Contenido neto: 750 ml"
                />
            </div>
        </div>
    )
}
export default Item;