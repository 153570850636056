import React from "react";
import facebook from "../img/facebook-brands.svg";
import whatsapp from "../img/whatsapp-square-brands.svg";
import instagram from "../img/instagram.svg";
import mail from '../img/envelope-solid.svg';
const Redes = () => {
    return (
        <div className="d-flex justify-content-center pt-5">
            <div className="redes pt-1 pb-1">
                <div id="zoom" className="pl-2 pr-2">
                    <a target="_blank" rel="noopener" href="https://www.instagram.com/mezcalgranremedio/">
                            <img src={instagram} alt="logoInstagram" width={30} />
                    </a>
                </div>
                <div id="zoom" className="pr-2">
                    <a target="_blank" rel="noopener" href="https://www.facebook.com/profile.php?id=100080763505793"> 
                            <img src={facebook} alt="logoFacebook" width={30} />
                    </a>
                </div>
                <div id="zoom" className="pr-2">
                    <a href="#">
                            <img src={whatsapp} alt="logoWhatsapp" width={30} />
                    </a>
                </div>
                <div id="zoom" className="pr-2">
                    <a href="mailto:elgranremediomezcal@gmail.com">
                            <img src={mail} alt="logoMail" width={35} />
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Redes;