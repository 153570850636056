import React from "react";
import logo from "../img/logo.png";
import logoWebp from "../img/logo.webp";
import { Link } from "react-router-dom";
import "../index.css";
export default function Navmod(props) {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div >
        <Link
          id="mig-header" className="navbar-brand pl-4"
          to="/">
          <picture>
            <source type="image/webp" srcset={logoWebp} />
            <source type="image/jpeg" srcset={logo} />
            <img id="mig-header" className="pl-5" src={logoWebp} width={220} height={50} alt="logoimg" />
          </picture>

        </Link>
      </div>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse " id="navbarText">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/nosotros">Nosotros </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="productos">Productos</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="contacto">Contacto</Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}
