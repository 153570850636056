//import { faVolumeDown } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useEffect, useRef } from 'react';
//import ReactPlayer from 'react-player/lazy'
import celehd from "../videos/celehd.mp4";
import ReactPlayer from "react-player"; 


export default function Video(props) {
  
  useEffect(() => {  
    
    const d = document;
    const video = d.getElementsByTagName("video");
  
    function nameId() {
      const videoCele = d.getElementById("rPlayer");
      videoCele.id = "pNoVisible";
      const mezcalHome = d.getElementById("mezcalNoHome");
      mezcalHome.id = "MezcalHome";
    };
    const sTOut = setTimeout(() => {
      nameId();
    }, 19000)
  },[]);

  return (
    <div id="videocele">
             
      <video id="rPlayer" autoplay="autoplay" muted controls width="320px"  >
        <source src={celehd + `${"#t=0,18"}`}
          type="video/mp4"
  ></source></video>
        {/*<ReactPlayer id="rPlayer"
        url={celehd + `${"#0,18"}`}
        controls
        width="105%"
        muted
        autoplay="autoplay"
      />
*/}
    
 </div>
    )
  
};
