import React from "react";
import collection_0 from "../img/seleccionmod.jpeg";
import collection_0Webp from "../img/seleccionmod.webp";
import collection_1 from "../img/jimarmod.jpg";
import collection_1Webp from "../img/jimarmod.webp";
import collection_2 from "../img/recolectarmod.jpg";
import collection_2Webp from "../img/recolectarmod.webp";
import collection_3 from "../img/transportemod.jpg";
import collection_3Webp from "../img/transportemod.webp";
///////////
import collection_4 from "../img/hornearmod.jpeg";
import collection_4Webp from "../img/hornearmod.webp";
/////////
import collection_5 from "../img/triturarmod.jpg";
import collection_5Webp from "../img/triturarmod.webp";
import collection_6 from "../img/fermentarmod.jpg";
import collection_6Webp from "../img/fermentarmod.webp";
import collection_7 from "../img/destilarmod.jpg";
import collection_7Webp from "../img/destilarmod.webp";
//////////
import collection_8 from "../img/probarmod.jpeg";
import collection_8Webp from "../img/probarmod.webp";
export default function Slider(props) {
  return (
    <div>
      <h2 className="elaboracion">Descubra cómo hacemos mezcal</h2>
      <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="4"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="5"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="6"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="7"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="8"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="9"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="10"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <picture id="imgcarrusel">
              <source type="image/webp" srcset={collection_0Webp} />
              <source type="image/jpeg" srcset={collection_0} />
              <img src={collection_0Webp} className="d-block w-40 h-40" alt="img-coll-1" />
            </picture>
            <div className="carousel-caption d-none d-md-block">
              <h5 className="pieFoto">1. Selección del Agave</h5>
              <p className="pieFoto">Los agaves que utilizamos nacen de forma natural y orgánica sin intervención
                humana, por lo tanto, no contiene herbicidas, ni fertilizantes. Seleccionamos
                agaves maduros con un periodo de vida de 7 a 10 años y se identifican por su olor y
                vista, estos no deben tener tallo o quiote.</p>
            </div>
          </div>
          <div className="carousel-item">
            <picture id="imgcarrusel">
              <source type="image/webp" srcset={collection_1Webp} />
              <source type="image/jpeg" srcset={collection_1} />
              <img src={collection_1Webp} className="d-block w-40 h-40" alt="img-coll-2" />
            </picture>
            <div className="carousel-caption d-none d-md-block">
              <h5 className="pieFoto">2. Jimado</h5>
              <p className="pieFoto">Una vez seleccionada la planta, cortamos las pencas verdes y pulposas del agave,
                dando como resultado una “piña blanca” de la cual se desprende un olor
                característico y único de su madurez.</p>
            </div>
          </div>
          <div className="carousel-item">
            <picture id="imgcarrusel">
              <source type="image/webp" srcset={collection_2Webp} />
              <source type="image/jpeg" srcset={collection_2} />
              <img src={collection_2Webp} className="d-block w-40 h-40" alt="img-coll-3" />
            </picture>
            <div className="carousel-caption d-none d-md-block">
              <h5 className="pieFoto">3. Transporte</h5>
              <p className="pieFoto">Los agaves maduros que seleccionamos se encuentran en el medio silvestre,
                entre árboles y rocas, retirados uno del otro, después de jimarlos, los juntamos en
                un punto estratégico para poder trasportarlos al horno. Para transportarlos
                utilizamos cuatrimotos y camionetas de carga. Anteriormente se usaban animales
                de carga.</p>
            </div>
          </div>
          <div className="carousel-item">
            <picture id="imgcarrusel">
              <source type="image/webp" srcset={collection_3Webp} />
              <source type="image/jpeg" srcset={collection_3} />
              <img src={collection_3Webp} className="d-block w-40 h-40" alt="img-coll-4" />
            </picture>
            <div className="carousel-caption d-none d-md-block">
              <h5 className="pieFoto">4. Recolecta</h5>
              <p className="pieFoto">En cada carga recolectamos entre 80 a 100 piñas jimadas y cada una tiene un
                peso aproximado de 30 kilogramos. También, recolectamos leña de árboles recién
                cortados con la finalidad de crear mucho carbón y pasto silvestre suficiente para
                cubrir el agave y de esta manera evitar que mezcle con la tierra cuando este en el
                horno.</p>
            </div>
          </div>
          <div className="carousel-item">
            <picture id="imgcarrusel">
            <source type="image/webp" srcset={collection_4Webp} />
            <source type="image/jpeg" srcset={collection_4} />
            <img src={collection_4} className="d-block w-40 h-40" alt="img-coll-5" />
            </picture>
            <div className="carousel-caption d-none d-md-block">
              <h5 className="pieFoto">5. Horneado</h5>
              <p className="pieFoto">Nuestro horno tiene una apertura de 10 metros de diámetro y 4 metros de
                profundidad en la tierra, con las paredes forradas de piedra.</p>
            </div>
          </div>
          <div className="carousel-item">
            <picture id="imgcarrusel">
              <source type="image/webp" srcset={collection_5Webp} />
              <source type="image/jpeg" srcset={collection_5} />
              <img src={collection_5Webp} className="d-block w-40 h-40" alt="img-coll-6" />
            </picture>
            <div className="carousel-caption d-none d-md-block">
              <h5 className="pieFoto">6. Molienda</h5>
              <p className="pieFoto">En una pileta de madera de 2x2x1 m. molemos el agave cocido con la finalidad de
                extraer el jugo (mejor conocido como la miel). Este se golpea en repetidas
                ocasiones con un mazo de madera dentro de la pileta hasta molerlo.</p>
            </div>
          </div>
          <div className="carousel-item">
            <picture id="imgcarrusel">
              <source type="image/webp" srcset={collection_6Webp} />
              <source type="image/jpeg" srcset={collection_6} />
              <img src={collection_6Webp} className="d-block w-40 h-40" alt="img-coll-7" />
            </picture>
            <div className="carousel-caption d-none d-md-block">
              <h5 className="pieFoto">7. Fermentación</h5>
              <p className="pieFoto">La fermentación la realizamos en recipientes de cuero de ganado vacuno. Por
                cada porción de agave molido, colocamos una cantidad de agua proporcional. Una
                vez dentro del recipiente se pisa con los pies limpios y se deja fermentar de 8 a 10
                días.</p>
            </div>
          </div>
          <div className="carousel-item">
            <picture id="imgcarrusel">
              <source type="image/webp" srcset={collection_7Webp} />
              <source type="image/jpeg" srcset={collection_7} />
              <img src={collection_7Webp} className="d-block w-40 h-40" alt="img-coll-8" />
            </picture>
            <div className="carousel-caption d-none d-md-block">
              <h5 className="pieFoto">8. Destilación</h5>
              <p className="pieFoto">Nuestro proceso tiene dos destilaciones. En la primera destilación, vertimos el
                agave fermentado en una olla de madera con fondo de cobre y en la tapa fluye
                agua fría, al realizar este paso se obtiene mezcal ordinario. En la segunda
                destilación, vertimos en la olla de madera el mezcal ordinario que se produjo en la
                primera destilación, para obtener el mezcal alto.</p>
            </div>
          </div>
          <div className="carousel-item">
            <picture id="imgcarrusel">
            <source type="image/webp" srcset={collection_8Webp} />
            <source type="image/jpeg" srcset={collection_8} />
            <img src={collection_8} className="d-block w-40 h-40" alt="img-coll-9" />
            </picture>
            
            <div className="carousel-caption d-none d-md-block">
              <h5 className="pieFoto">9. Catación</h5>
              <p className="pieFoto">Al inicio de la producción el mezcal de alto tiene un alto grado de alcohol y al
              pasar un rato disminuye. Nuestro catador presta puntual atención a su sabor, color
                y olor pues de él depende la calidad que obtengamos.</p>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-target="#carouselExampleCaptions" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-target="#carouselExampleCaptions" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </button>
      </div>
    </div>
  )
}