import React from "react";
import ContactoDefault from "../components/ContactoDefault";
const Productos = (props) => {
    return (
        <div>
        <div id="producto" className="card bg-dark text-white">
  <img src={props.imagen} className="card-img" alt="imgproduct"/>
  <div className="card-img-overlay">
                <h4 id="texto-producto" className="card-title">{props.nombre}</h4>
                <h5 id="texto-producto" className="card-text">{props.frase1}</h5>
                <h5 id="texto-producto" className="card-text">{ props.frase2 }</h5>
                <p id="cuerpo-producto-1" className="card-text">{props.cuerpo1}</p>
                <p id="cuerpo-producto-2" className="card-text">Tipo/ categoría: Blanco/joven<br /> Agave empleado: cupreata<br /> Crecimiento del agave: 7 a 10 años<br /> Destilaciones: 2<br /> Tipo de horno: de tierra y piedra<br /> Tipo de molino: pileta de piedra y mazo de madera<br /> Tipo de destilación: olla de madera con fondo de cobre<br /> Zona de origen: Región Tierra Caliente, Guerrero<br /> Riqueza alcohólica: 45º<br /> Contenido neto: 750 ml.</p>
            </div>
            </div>
            <ContactoDefault/>
            </div>
        
    )
}
export default Productos;