import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Navmod from "./components/Navmod";
import Home from "./components/Home";
import Nosotros from "./components/Nosotros";
import Footer from "./components/Footer";
import Item from "./components/Item";
import Redes from "./components/Redes";
import Contacto from "./components/Contacto";
import "./index.css";


function App() {
 
  return (
    <Router>
      <div className="navigator">
      <Navmod />
      </div>
      <div>
        <div className="home">
          <Route path="/" exact component={Home} />
          <div className="descimage">
            <Route path="/nosotros" exact component={Nosotros} />
          </div>
          <Route path="/productos" exact component={Item} />
          <Route path="/contacto" exact component={Contacto} />
        </div>
      </div>
    
    </Router>
  );
}
export default App;