import React from "react";
import banner from "../img/bannerGRM.png";
import "../index.css";

const Footer = () => {
    return (
        <div className="w-100" >
            <span>ESTE CONTENIDO ES SÓLO PARA MAYORES DE EDAD Y NO DEBE COMPARTIRSE CON MENORES.</span>
            <hr /><br />
            <div id="banner">
            <span id="textBanner" >Pedidos +52 755 102 4094</span><br />
            <span id="textBanner" >elgranremediomezcal@gmail.com</span><br />
            <span id="textBanner">El Gran Remedio ©2022 | Todos los derechos reservados</span>
            <p id="textBanner"><a className="creator" target="_blank" rel="noreferrer" href="https://www.solidglassbridge.com/">Powered by SGB</a></p>
            </div>
        </div>



    )
}
export default Footer;