import React from "react";
import logo from '../img/insignia-logo.png';
import sirviendo from "../img/CelerinoSirviendo.jpg";
import sirviendoWebp from "../img/CelerinoSirviendo.webp";
import firma from "../img/firma.png";
import firmaWebp from "../img/firma.webp";
import "../index.css";

const Description_1 = () => {
    return (
        <div id="desc">
              <picture>
                   
                    <source type="image/png" srcset={firma} />
                <img src={firma} width={200} alt="firma" />
                </picture>
            <p>El Gran Remedio nace de un arraigo y conexión cultural entre la tierra,
                el arduo trabajo del campo y la calidez del paladar calentano.
            </p>
                    <img className="insignia" src={logo} width={50} alt="insigniaLogo" />
            <div className="pl-4">
                <h3>Ideal para cualquier momento</h3>

                <p>El Gran Remedio te acompaña a calentar una tarde fría, a cerrar la
                    carne asada de los domingos, marinar los alimentos, digerir un
                    pozole, cerrar un negocio, preparar un mojito o cerrar los ojitos cuando se presente el insomnio.
                </p>
                <div className="sirviendo">
                <picture>
                    <source type="image/webp" srcset={sirviendoWebp} />
                    <source type="image/jpeg" srcset={sirviendo} />
                    <img src={sirviendoWebp} width={200} alt="insigniaLogo" />
                </picture>
                </div>
            </div>
        </div>
    )
}
export default Description_1;