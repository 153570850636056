import React from "react";
import Redes from "./Redes";
import Footer from "./Footer";
import Banner1 from "../img/banner1.jpg";
import Banner1Webp from "../img/banner1.webp";
import Banner2 from "../img/banner2.jpeg";
import Banner2Webp from "../img/banner2.webp";
import Banner4 from "../img/banner4.jpeg";
import Banner4Webp from "../img/banner4.webp";
export default function Contacto(props) {
    return (
        <div>  
            <div id="contactoFotos" class="card-deck">
                <div class="card">
                    <picture >
                        <source type="image/webp" srcset={Banner1Webp} />
                        <source type="image/jpg" srcset={Banner1} />
                        <img class="card-img-top" src={Banner1Webp} className="d-block w-30 h-30" alt="img-coll-3" />
                    </picture>
                </div>
                <div class="card">
                    <picture >
                        <source type="image/webp" srcset={Banner2Webp} />
                        <source type="image/jpg" srcset={Banner2} />
                        <img class="card-img-top" src={Banner2Webp} className="d-block w-30 h-30" alt="img-coll-3" />
                    </picture>
                </div>
                <div class="card">
                    <picture >
                        <source type="image/webp" srcset={Banner4Webp} />
                        <source type="image/jpg" srcset={Banner4} />
                        <img class="card-img-top" src={Banner4Webp} className="d-block w-30 h-30" alt="img-coll-3" />
                    </picture>
                </div>
            </div>
            
            <div>
            <div className="Redes pt-0">
                <Redes />
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>

        </div>


    );
}