import React from "react";
import celerino from "../img/nosotros-Celerino.png";
import celerinoWebp from "../img/nosotros-Celerino.webp";
import ContactoDefault from "../components/ContactoDefault";
const Nosotros = () => {
    return (
        <div>
            <div className="nosotros" >
                <p >El Gran Remedio conjuga la historia de cuatro generaciones de la familia Coria,
                    quienes se han dedicado a producir mezcal de manera artesanal. Actualmente
                    Celerino Coria (cuarta generación) continua con esta historia. Desde niño vio
                    como su padre dedicaba la mayor parte de su día produciendo mezcal para poder
                    llevar un ingreso económico a su casa y con el tiempo esta bebida se convirtió en
                    una de las mayores pasiones en la vida de Celerino. Al percatarse que en la
                    ciudad no había mezcales tan buenos como el que se hace en su tierra, decidió
                    empezar este gran proyecto: “El Gran Remedio”. Es decir, se inspiró a materializar
                    el sueño de embotellar el trabajo y conocimientos de sus generaciones para darle
                    una presentación formal. <br />
                    Con el Gran Remedio se contribuye a rescatar la
                    producción local y se da a conocer un mezcal de calidad, rescatando las raices de
                    la region y generando nuevos empleos.
                </p>
                <picture>
                    <source type="image/webp" srcset={celerinoWebp} />
                    <source type="image/jpeg" srcset={celerino} />
                    <img id="cele" src={celerinoWebp} width={280} height={200} alt="imgCele" />
                </picture>
               
            </div>
            <ContactoDefault/>
        </div>
        
    )
}
export default Nosotros;